import React from "react";

function BugSvg({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="295.232"
            height="296.117"
            x="0"
            y="0"
            viewBox="-87.947 -82 295.232 296.117"
            {...props}
        >
            <g>
                <linearGradient
                    id="SVGID_1_"
                    x1="169.697"
                    x2="3.477"
                    y1="192.779"
                    y2="-100.471"
                    gradientTransform="matrix(1 0 0 -1 0 108.875)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#A6A6A6"></stop>
                    <stop offset="0.028" stopColor="#8B8B8B"></stop>
                    <stop offset="0.073" stopColor="#666"></stop>
                    <stop offset="0.121" stopColor="#464646"></stop>
                    <stop offset="0.173" stopColor="#2D2D2D"></stop>
                    <stop offset="0.231" stopColor="#191919"></stop>
                    <stop offset="0.296" stopColor="#0B0B0B"></stop>
                    <stop offset="0.377" stopColor="#020202"></stop>
                    <stop offset="0.516"></stop>
                </linearGradient>
                <path
                    fill="url(#SVGID_1_)"
                    stroke="#000"
                    d="M-73.129 128.031s-6.306 14.865-5.405 21.622l-7.208-.454s-3.603-26.579 3.603-38.288C-74.932 99.197 92.192-46.753 92.192-46.753s31.082-18.017 61.713-3.151c0 0 27.479 22.071 4.505 65.315 0 0 0 38.293-3.153 41.444-3.154 3.151 13.517 2.254 26.128-8.108 0 0-7.66 20.72-23.426 25.677 0 0 16.893 12.838 21.171 28.828 0 0 5.408 13.514 14.417 20.271 0 0-15.654.228-26.804-15.313 0 0-10.249-22.974-20.047-24.663l-.899 2.703s-31.982 44.036-46.848 56.647c0 0-.003 21.622-10.362 39.19l-5.405.897s8.56-27.028 1.803-28.828l-18.02 4.957s-20.723 49.548-27.028 54.504H33.18s15.541-36.371 9.235-42.23l-115.544-43.356z"
                ></path>
                <radialGradient
                    id="SVGID_2_"
                    cx="9.761"
                    cy="95.889"
                    r="217.495"
                    gradientTransform="matrix(1.0668 -.0121 .0121 -1.0559 17.894 121.505)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.313" stopColor="#FA5151"></stop>
                    <stop offset="0.339" stopColor="#F24E4E"></stop>
                    <stop offset="0.379" stopColor="#DC4747"></stop>
                    <stop offset="0.429" stopColor="#B83C3C"></stop>
                    <stop offset="0.486" stopColor="#862C2C"></stop>
                    <stop offset="0.548" stopColor="#471717"></stop>
                    <stop offset="0.61" stopColor="#010101"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_2_)"
                    d="M-74.821 135.244c30.562 47.26 104.705 53.647 165.606 14.262 60.908-39.381 85.513-109.615 54.97-156.874C115.189-54.626 41.044-61.018-19.86-21.634-80.771 17.75-105.376 87.99-74.821 135.244z"
                ></path>
                <linearGradient
                    id="SVGID_3_"
                    x1="42.772"
                    x2="12.138"
                    y1="82.857"
                    y2="-53.183"
                    gradientTransform="matrix(1 0 0 -1 0 108.875)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFF"></stop>
                    <stop offset="0.72"></stop>
                </linearGradient>
                <path
                    fill="url(#SVGID_3_)"
                    stroke="#000"
                    d="M-73.257 131.426c30.69 47.462 103.931 54.673 163.588 16.095C149.985 108.948 173.474 39.2 142.79-8.265 112.097-55.726 38.858-62.934-20.801-24.359c-59.662 38.573-83.146 108.322-52.456 155.785z"
                    opacity="0.32"
                ></path>
                <circle
                    cx="151.653"
                    cy="-27.72"
                    r="4.954"
                    fill="#FFF"
                    stroke="#000"
                    strokeWidth="0.5"
                ></circle>
                <radialGradient
                    id="SVGID_4_"
                    cx="177.893"
                    cy="164.185"
                    r="23.623"
                    gradientTransform="matrix(1 0 0 -1 0 108.875)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#1D1D1D"></stop>
                    <stop offset="0.005" stopColor="#FFF"></stop>
                    <stop offset="0.006" stopColor="#EBEBEB"></stop>
                    <stop offset="0.007" stopColor="#B7B7B7"></stop>
                    <stop offset="0.009" stopColor="#646464"></stop>
                    <stop offset="0.011" stopColor="#202020"></stop>
                    <stop offset="0.516"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_4_)"
                    d="M148.499 -41.458L167.419 -71.189 207.285 -61.729 171.474 -65.107 153.905 -39.431z"
                ></path>
                <radialGradient
                    id="SVGID_5_"
                    cx="62.397"
                    cy="225.435"
                    r="14.717"
                    gradientTransform="matrix(1.2 0 0 -1.2286 34.264 209.16)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#1D1D1D"></stop>
                    <stop offset="0.005" stopColor="#FFF"></stop>
                    <stop offset="0.006" stopColor="#EBEBEB"></stop>
                    <stop offset="0.007" stopColor="#B7B7B7"></stop>
                    <stop offset="0.009" stopColor="#646464"></stop>
                    <stop offset="0.011" stopColor="#202020"></stop>
                    <stop offset="0.516"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_5_)"
                    d="M129.918 -55.31L110.254 -82 88.362 -69.548 107.82 -75.359 125.863 -53.621z"
                ></path>
                <g opacity="0.44">
                    <radialGradient
                        id="SVGID_6_"
                        cx="51.142"
                        cy="67.438"
                        r="60.208"
                        fx="25.142"
                        fy="33.438"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0" stopColor="#EB1414"></stop>
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0" stopColor="#F5EBEB"></stop>
                        <stop offset="0" stopColor="#F7EDED"></stop>
                        <stop offset="0.138" stopColor="#F6D8D8"></stop>
                        <stop offset="0.422" stopColor="#F3A1A1"></stop>
                        <stop offset="0.826" stopColor="#EE4A4A"></stop>
                        <stop offset="1" stopColor="#EC2323"></stop>
                        <stop offset="1" stopColor="#EC2525"></stop>
                        <stop offset="1" stopColor="#ED2C2C"></stop>
                        <stop offset="1" stopColor="#EE3131"></stop>
                        <stop offset="1" stopColor="#ED2E2E"></stop>
                        <stop offset="1" stopColor="#ED2C2C"></stop>
                    </radialGradient>
                    <path
                        fill="url(#SVGID_6_)"
                        d="M-74.216 133.014c30.69 47.465 104.023 54.615 163.791 15.963 59.778-38.644 83.349-108.452 52.67-155.917C111.55-54.401 38.217-61.55-21.556-22.903c-59.771 38.646-83.353 108.457-52.66 155.917z"
                        opacity="0.83"
                    ></path>
                </g>
                <g opacity="0.44">
                    <radialGradient
                        id="SVGID_7_"
                        cx="-4.858"
                        cy="-14.563"
                        r="88"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0" stopColor="#EB1414"></stop>
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0.22" stopColor="#F7EDED"></stop>
                        <stop offset="0.3" stopColor="#F7E8E8"></stop>
                        <stop offset="0.39" stopColor="#F6DBDB"></stop>
                        <stop offset="0.486" stopColor="#F5C4C4"></stop>
                        <stop offset="0.585" stopColor="#F3A4A4"></stop>
                        <stop offset="0.688" stopColor="#F17B7B"></stop>
                        <stop offset="0.79" stopColor="#EE4A4A"></stop>
                        <stop offset="0.863" stopColor="#EC2323"></stop>
                        <stop offset="0.954" stopColor="#EC2525"></stop>
                        <stop offset="0.987" stopColor="#ED2C2C"></stop>
                        <stop offset="1" stopColor="#EE3131"></stop>
                        <stop offset="1" stopColor="#ED2E2E"></stop>
                        <stop offset="1" stopColor="#ED2C2C"></stop>
                    </radialGradient>
                    <path
                        fill="url(#SVGID_7_)"
                        d="M-73.217 132.014c30.69 47.465 104.023 54.615 163.791 15.963 59.778-38.644 83.349-108.452 52.67-155.917-30.694-47.461-104.027-54.61-163.8-15.963-59.771 38.646-83.353 108.457-52.661 155.917z"
                        opacity="0.83"
                    ></path>
                </g>
                <g opacity="0.68">
                    <radialGradient
                        id="SVGID_8_"
                        cx="82.143"
                        cy="-19.563"
                        r="100.807"
                        fx="118.142"
                        fy="-8.563"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0" stopColor="#EB1414"></stop>
                        <stop offset="0" stopColor="#F4EAEA"></stop>
                        <stop offset="0" stopColor="#F7EDED"></stop>
                        <stop offset="0.07" stopColor="#F7E8E8"></stop>
                        <stop offset="0.15" stopColor="#F6DBDB"></stop>
                        <stop offset="0.234" stopColor="#F5C4C4"></stop>
                        <stop offset="0.322" stopColor="#F3A4A4"></stop>
                        <stop offset="0.411" stopColor="#F17C7C"></stop>
                        <stop offset="0.502" stopColor="#EE4A4A"></stop>
                        <stop offset="0.566" stopColor="#EC2323"></stop>
                        <stop offset="0.856" stopColor="#EC2525"></stop>
                        <stop offset="0.96" stopColor="#ED2C2C"></stop>
                        <stop offset="1" stopColor="#EE3131"></stop>
                        <stop offset="1" stopColor="#ED2E2E"></stop>
                        <stop offset="1" stopColor="#ED2C2C"></stop>
                    </radialGradient>
                    <path
                        fill="url(#SVGID_8_)"
                        d="M-73.216 132.014c30.69 47.465 104.023 54.615 163.791 15.963 59.778-38.644 83.349-108.452 52.67-155.917-30.694-47.461-104.027-54.61-163.8-15.963-59.772 38.646-83.354 108.457-52.661 155.917z"
                        opacity="0.83"
                    ></path>
                </g>
                <radialGradient
                    id="SVGID_9_"
                    cx="29.125"
                    cy="1.006"
                    r="27.521"
                    gradientTransform="scale(1 -1) rotate(3.331 1901.469 -53.442)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#7A6F6F"></stop>
                    <stop offset="0.148" stopColor="#746A6A"></stop>
                    <stop offset="0.347" stopColor="#635A5A"></stop>
                    <stop offset="0.575" stopColor="#474141"></stop>
                    <stop offset="0.822" stopColor="#201F1F"></stop>
                    <stop offset="0.896" stopColor="#131313"></stop>
                    <stop offset="0.954" stopColor="#161616"></stop>
                    <stop offset="0.999" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#1D1D1D"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_9_)"
                    d="M27.32 76.842c12.916-.751 24.194 12.53 25.191 29.669.996 17.138-8.667 31.639-21.583 32.39-12.915.751-24.193-12.531-25.19-29.669-.995-17.139 8.667-31.639 21.582-32.39z"
                ></path>
                <radialGradient
                    id="SVGID_10_"
                    cx="117.12"
                    cy="59.435"
                    r="31.11"
                    gradientTransform="matrix(.9275 .091 .0976 -.9952 -1.558 102.751)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#7A6F6F"></stop>
                    <stop offset="0.153" stopColor="#756A6A"></stop>
                    <stop offset="0.343" stopColor="#665D5D"></stop>
                    <stop offset="0.552" stopColor="#4C4646"></stop>
                    <stop offset="0.773" stopColor="#2A2727"></stop>
                    <stop offset="0.896" stopColor="#131313"></stop>
                    <stop offset="0.954" stopColor="#161616"></stop>
                    <stop offset="0.999" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#1D1D1D"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_10_)"
                    d="M135.584 60.512c-4.848 19.268-18.945 32.09-31.488 28.636-12.541-3.453-18.777-21.872-13.933-41.14 4.852-19.27 18.947-32.092 31.491-28.641 12.541 3.451 18.78 21.873 13.93 41.145z"
                ></path>
                <radialGradient
                    id="SVGID_11_"
                    cx="112.231"
                    cy="209.175"
                    r="21.578"
                    gradientTransform="matrix(1.0277 .213 .203 -.9792 -86.867 160.216)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#7A6F6F"></stop>
                    <stop offset="0.146" stopColor="#726868"></stop>
                    <stop offset="0.369" stopColor="#5D5555"></stop>
                    <stop offset="0.642" stopColor="#3A3636"></stop>
                    <stop offset="0.896" stopColor="#131313"></stop>
                    <stop offset="0.954" stopColor="#161616"></stop>
                    <stop offset="0.999" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#1D1D1D"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_11_)"
                    d="M97.785-15.135C96.02-6.611 82.566-2.189 67.732-5.265 52.907-8.339 42.317-17.744 44.084-26.269c1.767-8.528 15.224-12.946 30.047-9.874 14.836 3.077 25.426 12.483 23.654 21.008z"
                ></path>
                <radialGradient
                    id="SVGID_12_"
                    cx="-268.576"
                    cy="51.705"
                    r="25.651"
                    gradientTransform="matrix(.8698 -.6221 -.6225 -.8705 245.284 -128.637)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#7A6F6F"></stop>
                    <stop offset="0.165" stopColor="#756B6B"></stop>
                    <stop offset="0.346" stopColor="#685F5F"></stop>
                    <stop offset="0.535" stopColor="#524B4B"></stop>
                    <stop offset="0.729" stopColor="#343030"></stop>
                    <stop offset="0.896" stopColor="#131313"></stop>
                    <stop offset="0.954" stopColor="#161616"></stop>
                    <stop offset="0.999" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#1D1D1D"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_12_)"
                    d="M8.093-27.022c5.264 7.365-3.271 22.492-19.071 33.79-15.799 11.299-32.872 14.486-38.136 7.124-5.266-7.361 3.27-22.492 19.07-33.787 15.798-11.3 32.87-14.49 38.137-7.127z"
                ></path>
                <radialGradient
                    id="SVGID_13_"
                    cx="-69.524"
                    cy="25.781"
                    r="18.256"
                    gradientTransform="matrix(1.1138 0 0 -1.1698 9.813 116.463)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#020202"></stop>
                    <stop offset="0" stopColor="#242424"></stop>
                    <stop offset="0" stopColor="#7A6F6F"></stop>
                    <stop offset="0.146" stopColor="#726868"></stop>
                    <stop offset="0.369" stopColor="#5D5555"></stop>
                    <stop offset="0.642" stopColor="#3A3636"></stop>
                    <stop offset="0.896" stopColor="#131313"></stop>
                    <stop offset="0.954" stopColor="#161616"></stop>
                    <stop offset="0.999" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#202020"></stop>
                    <stop offset="1" stopColor="#1D1D1D"></stop>
                </radialGradient>
                <path
                    fill="url(#SVGID_13_)"
                    d="M-48.058 86.307c0 12.224-10.333 22.131-23.079 22.131-12.748 0-16.058-8.854-16.058-21.079 0-12.223 3.31-23.184 16.058-23.184 12.746 0 23.079 9.907 23.079 22.132z"
                ></path>
            </g>
        </svg>
    );
}

export default BugSvg;
