import { useEffect } from 'react';
import { markdocRenderByRawText } from '../helpers/markdocHelper';
import { Document, UserReport } from '../components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { activeSideBar, deactiveSideBar, getCurrentSideBar } from '../helpers/scrollHelper';

function Documentation() {
    const markdocs = useSelector((state) => state.markdocs.value);
    const narrowing = useSelector(state => state.mode.narrowingSidebar);
    const location = useLocation();
    document.title = 'Documentation';
    const isScrolling = useSelector((state) => state.scrollHandler.isScrolling);

    useEffect(() => {
        let hash = location.hash.slice(1);
        document.getElementById(hash) && setTimeout(() => {
            document.getElementById(hash).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);

    }, [location.hash]);

    useEffect(() => {
        let hash = location.hash.slice(1);
        document.getElementById(hash) && setTimeout(() => {
            document.getElementById(hash).scrollIntoView();
        }, 100);
    }, [markdocs]);

    useEffect(() => {
        if (isScrolling) return;
        const scrollHandler = () => {
            let current = getCurrentSideBar();
            const id = current?.getAttribute('id')
            document.getElementById(`tab-${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            deactiveSideBar(id);
            activeSideBar(id);
        }
        document.addEventListener("scroll", scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [isScrolling])

    return (
        <>
            <div className={`documentation relative ${narrowing ? '' : 'opacity-0 md:opacity-100'}`}>
                {
                    markdocs?.map((markdoc, index) => (
                        <div id={markdoc.slug} className={`docs border-b ${index % 2 !== 0 ? 'bg-[#FFFFFD]' : ''}`} key={markdoc.id}>
                            <div className='p-3 lg:p-10 container mx-auto'>
                                {
                                    <Document name={markdoc.name} ancestors={markdoc.ancestors} slug={markdoc.slug}>
                                        {
                                            markdocRenderByRawText(markdoc.content ?? '')
                                        }
                                    </Document>
                                }
                            </div>
                        </div>
                    ))
                }
                <UserReport />
            </div>
        </>

    );
}


export default Documentation;
