import axios from 'axios';

const url = `${process.env.REACT_APP_TAPI_API}/user-reports`;

const createUserReport = (data) => {
    let formData = new FormData();
    for (let key in data) {
        key != 'files' ? formData.append(key, data[key]) : data.files.map((file) => formData.append("files[]", file))
    }

    return axios({
        method: 'post',
        url: url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export {
    createUserReport
}
