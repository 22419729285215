export const param = {
    render: 'Param',
    children: ['paragraph', 'tag', 'list', 'fence'],
    attributes: {
        name: {
            type: String
        },
        type: {
            type: String
        },
        required: {
            type: String
        },
        more: {
            type: String
        },
    }
};
