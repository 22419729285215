import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    value: '',
    editing: false,
    narrowingSidebar: window.screen.width < 1200,
}

export const mode = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        setMode: (state, mode) => {
            state.value = mode.payload
        },
        setEditing: (state, editing) => {
            state.editing = editing.payload
        },
        setNarrowingSidebar: (state, value) => {
            state.narrowingSidebar = value.payload
        },
    },
})

export const { setMode, setEditing, setNarrowingSidebar } = mode.actions

export default mode.reducer
