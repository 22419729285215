export function Child({ name, type, required, more, children, ...props }) {
    return (
        <div {...props} className="text-sm param-child">
            <span className='font-bold mr-2'>{name}</span>
            <span className={`text-xs mr-1 rq-${required?.toLowerCase()}`}>{required}</span>
            <span className={`text-xs mr-1 type-${type?.toLowerCase()}`}>{type}</span>
            <span className='text-xs mr-1'>{more}</span>
            <p>{children}</p>
        </div>
    );
}
