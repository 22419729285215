import { Children } from 'react';

export function Document({ children, name, ancestors, slug, ...props }) {
    if (children.type) children = children.props.children;
    let columns = []

    Children.forEach(children, (child) => {
        child.type?.name == 'Column' && columns.push(child);
    });
    return (
        <div {...props} >
            <div className='relative'>
                <h2>{name}</h2>
                <div className='xs:block md:flex md:gap-8 lg:gap-12'>
                    {
                        columns.map((column, index) => (
                            <div key={index} className={`${window.screen.width >= 768 ? `flex-grow w-1/${columns.length}` : ''}`}>{column}</div>
                        ))
                    }
                </div>
            </div>
        </div >
    );
}
