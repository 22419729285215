import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, ChevronDoubleRightIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { isEditorPage, managementUrl } from '../..//helpers/urlHelper';
import { Link, useLocation, useParams } from 'react-router-dom';

export default function SearchModal({ open, setOpen, query, setQuery, result, ...props }) {
    const { version } = useParams();
    const location = useLocation();
    const close = () => {
        setOpen(false);
        setQuery('');
    }

    useEffect(() => {
        close();
    }, [location]);

    return (
        <Transition.Root show={open || false} as={Fragment} {...props}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className='sm:p-3 md:p-4 flex min-h-full justify-center text-center items-center'>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className='min-w-[22rem] md:min-w-[46rem] mx-8 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all'>
                                <div className='flex items-center border-b mb-1 px-2'>
                                    <MagnifyingGlassIcon className='h-5 w-5' />
                                    <input
                                        autoFocus
                                        value={query}
                                        onChange={(event) => { setQuery(event.target.value) }}
                                        className="border-0 focus:outline-none focus-visible:none block w-full rounded-md py-1.5 px-3 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                        placeholder='Search...' />
                                </div>
                                <div className={`overflow-x-hidden overflow-y-auto text-sm ${result.length ? 'h-64' : 'h-32'}`}>
                                    {
                                        result.length ? result.map((item) => (
                                            <Link
                                                key={item.id}
                                                to={isEditorPage() ? managementUrl() + item.id : `/${version}/#${item.slug}`}
                                                className='no-underline flex items-center w-full'
                                            >
                                                <div className='w-6'>
                                                    <DocumentTextIcon className='w-5 h-5 text-purple-500 font-semibold text-sm' />
                                                </div>
                                                <div className='hover:bg-gray-200 rounded px-2 py-1 flex items-center w-full'>
                                                    {
                                                        item?.ancestors.map((ancestor) => (
                                                            ancestor.parent_id !== null &&
                                                            <div key={ancestor.id} className='flex items-center text-sm'>
                                                                <div className='max-w-52 truncate'>{ancestor.name}</div>
                                                                <ChevronDoubleRightIcon className='w-4 h-4 mx-2' />
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='text-sm'>{item.name}</div>
                                                </div>
                                            </Link>
                                        )) : <div className='mt-3 text-center'>No results were found.</div>
                                    }
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
