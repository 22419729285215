import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { getMarkdocTrees, getMarkdocs, updateMarkdoc } from '../../services/markdocs.js';
import { managementUrl } from '../../helpers/urlHelper.js';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setMarkdocTrees, setMarkdocs } from '../../stores/markdocs.js';


export default function UpdateDocumentModal({ open, setOpen, markdoc, ...props }) {
    const [name, setName] = useState('');
    const [template, setTemplate] = useState('template_1');
    const markdocs = useSelector((state) => state.markdocs.value);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setName(markdoc?.name);
    }, [markdoc]);
    const url = managementUrl();

    const getSiblings = () => {
        if (!markdoc) return;

        return markdocs.filter((item) => {
            return item.id !== markdoc.id && item.parent_id == markdoc.parent_id
        })
    }
    const siblings = getSiblings();

    useEffect(() => {
        name?.length > 255 ? setError('The document name may not be greater than 255 characters.') : setError('');
        siblings && siblings.map((sibling) =>
            sibling.name == name ? setError('The document name may not be duplicated at the same level.') : setError('')
        )
    }, [siblings, name]);

    const save = async () => {
        if (markdoc.id) {
            try {
                await updateMarkdoc(markdoc.id, null, name);
                toast("Updated successfully!", { type: "success" })
                const markdocResponse = await getMarkdocs();
                dispatch(setMarkdocs(markdocResponse.data.markdocs));
                const response = await getMarkdocTrees();
                dispatch(setMarkdocTrees(response.data.markdocs));
                navigate(url + markdoc.id);
            } catch (error) {
                toast("Something went wrong!", { type: "error" })
            }
        } else {
            navigate({
                pathname: url,
                search: createSearchParams({ name, template, parent_id: markdoc.parent_id }).toString(),
            });
        }
        setTemplate('template_1')
    }
    return (
        <Transition.Root show={open ?? false} as={Fragment} {...props}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div>
                                        <Dialog.Title as="h3" className="text-center text-base font-semibold leading-6 text-gray-900 mb-7">
                                            {!markdoc?.id ? 'New Document' : 'Update Document'}
                                        </Dialog.Title>
                                        <div className='mb-3'>
                                            <div className='font-medium mb-1'>Document name:</div>
                                            <input value={name} onChange={(event) => { setName(event.target.value) }} className="border block w-full rounded-md py-1 px-3 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                                            <small className='text-red-500 text-left'>{error}</small>
                                        </div>
                                        {
                                            !markdoc?.id &&
                                            <>
                                                <div className='font-medium mb-1'>Select template:</div>
                                                <select value={template} onChange={(event) => { setTemplate(event.target.value) }} className="block w-full rounded-md border py-2 pl-3 pr-10 text-gray-900 sm:text-sm sm:leading-6">
                                                    <option value="template_1">Document Template</option>
                                                    <option value="template_2">Category Template</option>
                                                    {markdocs?.map((markdoc) => (
                                                        markdoc.parent_id != null &&
                                                        <option key={markdoc.id} value={markdoc.id} className='truncate'>{markdoc.name}</option>
                                                    ))}
                                                </select>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className="flex mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-1/2 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={() => { setOpen(false) }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={!name || error}
                                        type="button"
                                        className={`w-1/2 ml-3 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${!name || error ? 'bg-indigo-200 hover:bg-indigo-100' : 'bg-indigo-600 hover:bg-indigo-500'}`}
                                        onClick={() => { save(); setOpen(false); }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
