import { useRef, useState } from "react";
import { createUserReport } from "../services/user-reports";
import ThankYouSvg from "./svg/ThankYouSvg";
import WavingHandSvg from "./svg/WavingHandSvg";
import BugSvg from "./svg/BugSvg";
import { PaperClipIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useForm } from 'react-hook-form';

export default function UserReport({ ...props }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const initReport = {
        name: '',
        email: '',
        phone: '',
        feedback: '',
        files: [],
        error: ''
    }

    const [report, setReport] = useState(initReport);
    const [showReport, setShowReport] = useState(false);
    const uploadEl = useRef(null);
    const [isReported, setIsReported] = useState(false);

    const submit = async () => {
        await createUserReport(report)
        setIsReported(true);
        setReport(initReport)
        setTimeout(() => {
            setShowReport(false)
        }, 5000);

    }
    const uploadFile = (event) => {
        const files = event.target.files
        const errorMsg = 'Please ensure to upload a maximum of 5 files, with each file not exceeding 10MB.'
        const typeErrorMsg = 'Acctach file must be a file of type: jpg, jpeg, bmp, png, pdf.'
        const allowedTypes = ['image/jpg', 'image/jpeg', 'image/bmp', 'image/png', 'application/pdf']

        if (report.files.length + files.length > 5) {
            setReport({ ...report, error: errorMsg })
            return
        }
        let invalid = false
        Object.values(files).every((file) => {
            if (!allowedTypes.includes(file.type)) {
                invalid = true
                setReport({ ...report, error: typeErrorMsg })
                return false
            }
            if (file.size >= 10485760) {
                invalid = true
                setReport({ ...report, error: errorMsg })
                return false
            }
            return true
        })
        !invalid && setReport({ ...report, files: [...report.files, ...files], error: '' })
    }

    const removeFile = (index) => {
        report.files.splice(index, 1)
        setReport({ ...report, files: report.files })
    }

    return (
        <div {...props}>
            <div className='fixed bottom-4 right-4 rounded-full bg-linear-purple p-3 cursor-pointer z-10' onClick={() => { setShowReport(!showReport); setIsReported(false) }}>
                {
                    showReport ?
                        <XMarkIcon className='w-7 h-7 hover:w-9 hover:h-9 text-white transition-all' />
                        :
                        <BugSvg className='w-8 h-8 hover:w-9 hover:h-9 text-white transition-all' />
                }
            </div>
            {
                showReport &&
                <div className='fixed bottom-[5.5rem] right-4 shadow z-10 bg-white w-[23rem] rounded-lg'>
                    <div className='flex flex-col h-full'>
                        <div className='bg-linear-purple px-2 py-3 rounded-t-lg text-white font-semibold'>
                            <div className='flex items-center'>
                                <WavingHandSvg className="mr-2 w-8 h-8" />
                                Help us improve!
                            </div>
                        </div>
                        <div className='flex-grow h-full'>
                            <div className='p-4 text-sm text-gray-500'>
                                {
                                    isReported ?
                                        <div>
                                            <div className='flex justify-center mb-5'>
                                                <ThankYouSvg className="w-24 h-24" />
                                            </div>
                                            <div className='italic'>
                                                <p>Thank you for your feedback!</p>
                                                <p>We appreciate you taking the time to report this issue. Your feedback helps us identify problems and improve our API’s and it’s supporting documentation.</p>
                                                <p>We will review your report as soon as possible. If you provided any contact details, we may contact you if we need any additional details.</p>
                                            </div>
                                        </div>
                                        : <>
                                            <div className='italic mb-5 flex items-center mt-2'>
                                                <BugSvg className="mr-1 w-7 h-7" />
                                                Something is broken? Let us know!
                                            </div>
                                            <form onSubmit={handleSubmit((data) => submit())}>
                                                <div className="mb-2">
                                                    <label>Your name:</label>
                                                    <input {...register('name', { required: "Name is required." })} maxLength=" 255" className='w-full border rounded-xl px-2 py-1' value={report.name} onChange={(event) => { setReport({ ...report, name: event.target.value }) }} />
                                                    <div className="text-xs text-red-500">{errors.name?.message}</div>
                                                </div>
                                                <div className="mb-2">
                                                    <label>Your email:</label>
                                                    <input {...register('email', {
                                                        required: "Email is required.",
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Entered value does not match email format",
                                                        },
                                                    })} maxLength=" 255" className='w-full border rounded-xl px-2 py-1' value={report.email} onChange={(event) => { setReport({ ...report, email: event.target.value }) }} />
                                                    <div className="text-xs text-red-500">{errors.email?.message}</div>
                                                </div>
                                                <div className="mb-2">
                                                    <label>Your phone number:</label>
                                                    <input {...register('phone', { required: "Phone is required." })} maxLength=" 255" className='w-full border mb-2 rounded-xl px-2 py-1' value={report.phone} onChange={(event) => { setReport({ ...report, phone: event.target.value }) }} />
                                                    <div className="text-xs text-red-500">{errors.phone?.message}</div>
                                                </div>
                                                <div className="mb-2">
                                                    <label>Your feedback:</label>
                                                    <div className='relative'>
                                                        <textarea {...register('feedback', { required: "Feedback is required." })} maxLength=" 255" className='w-full border rounded-xl px-2 py-1' value={report.feedback} rows="3" onChange={(event) => { setReport({ ...report, feedback: event.target.value }) }}>
                                                        </textarea>
                                                        <PaperClipIcon className='w-5 h-5 ml-2 absolute right-3 bottom-3 cursor-pointer font-bold bg-white' onClick={() => uploadEl.current.click()} />
                                                        <input type="file" className='hidden' ref={uploadEl} multiple onChange={(event) => { uploadFile(event) }} />
                                                    </div>
                                                    <div className="text-xs text-red-500">{errors.feedback?.message}</div>
                                                    <div className='text-xs text-red-500'>{report.error}</div>
                                                </div>
                                                <div className='grid grid-cols-3 gap-2'>
                                                    {report.files.map((file, index) => (
                                                        <div className='flex items-center justify-between' key={index}>
                                                            <XMarkIcon className="w-5 h-5 text-red-500 hover:cursor-pointer hover:scale-110" onClick={() => removeFile(index)} />
                                                            <div className="w-full truncate italic un">{file.name}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='flex justify-end mt-3'>
                                                    <button className='border px-5 py-1.5 rounded bg-linear-purple text-white cursor-pointer hover:scale-105 transition-all'>
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}
