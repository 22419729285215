export function Box({ children, title, type, ...props }) {
    return (
        <div {...props} >
            <div className={`rounded-md text-sm box mb-6 box-${type}`}>
                <div className={`rounded-t font-medium uppercase p-3 bg-gray-100 title`}>
                    {title}
                </div>
                <div className='rounded bg-gray-50 max-h-[80vh] overflow-auto px-3 py-2 block content'>
                    {children}
                </div>
            </div>
        </div >
    );
}
