export function Route({ children, method, url, ...props }) {
    const methodName = method?.toUpperCase();

    return (
        <div {...props} >
            <div className='route rounded text-sm mb-3 bg-gray-100 p-2.5 break-words'>
                <span className={`mt-${methodName} pr-2 font-semibold`}>{methodName}</span>
                <span className=''>{url}</span>
                <span>{children}</span>
            </div>
        </div>
    );
}
