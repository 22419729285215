import MarkdocEditor from './pages/MarkdocEditor'
import Documentation from './pages/Documentation'
import Error404 from './pages/Error404'
import { Route, Routes } from 'react-router-dom';
import Authenticate from './layouts/Authenticate';

const Routers = () => {

    return (
        <Routes>
            <Route path="/"
                element={
                    <Authenticate auth={{ required: false }}>
                        <Documentation />
                    </Authenticate>
                }
                errorElement={<Error404 />}
            >
                <Route path=":version"
                    element={
                        <Authenticate auth={{ required: false }}>
                            <Documentation />
                        </Authenticate>
                    }
                />
            </Route>
            <Route
                path="/articles"
                element={
                    <Authenticate auth={{ required: true }}>
                        <MarkdocEditor />
                    </Authenticate>
                }
            >
                <Route
                    path=":id"
                    element={
                        <Authenticate auth={{ required: true }}>
                            <MarkdocEditor />
                        </Authenticate>
                    }
                />
            </Route>
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
}
export default Routers;
