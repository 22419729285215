export const box = {
  render: 'Box',
  children: ['paragraph', 'tag', 'list', 'fence'],
  attributes: {
    title: {
      type: String
    },
    type: {
      type: String
    },
  }
};
