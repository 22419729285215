import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setMode } from '../stores/mode';
import { useSelector } from 'react-redux';
import { isEditorPage, managementUrl } from '../helpers/urlHelper';
import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { getMarkdocTrees, getMarkdocs, getVersions } from '../services/markdocs';
import { useNavigate } from 'react-router-dom';
import { SearchModal } from '../components/modals'
import { toast } from 'react-toastify';
import { setMarkdocTrees, setMarkdocs, setVersions } from "../stores/markdocs";

const Header = () => {
    const { version } = useParams();
    const [open, setOpen] = useState(false)
    const location = useLocation();
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');
    const [result, setResult] = useState([]);
    const authenticated = useSelector(state => state.auth.authenticated);
    const editing = useSelector(state => state.mode.editing);
    const versions = useSelector(state => state.markdocs.versions);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setMode(isEditorPage() ? 'management' : 'document'))
        !isEditorPage() && !version && navigate(`/v2`)
    }, [location]);

    const setMarkdocData = async () => {
        try {
            const currentVer = isEditorPage() ? null : (version ?? 'v2')
            const markdocResponse = await getMarkdocs(currentVer);
            dispatch(setMarkdocs(markdocResponse.data.markdocs));
            const treeResponse = await getMarkdocTrees(currentVer);
            dispatch(setMarkdocTrees(treeResponse.data.markdocs));
        } catch (error) {
            toast("Something went wrong!", { type: "error" })
        }
    }

    useEffect(() => {
        (async () => {
            await setMarkdocData()
        })();
    }, [version]);

    useEffect(() => {
        if (!editing) {
            const keyupHandler = (event) => {
                event.key === '/' && setOpen(true)
            }
            document.addEventListener("keyup", keyupHandler);

            return () => {
                document.removeEventListener('keyup', keyupHandler)
            }
        }
    }, [editing])

    useEffect(() => {
        (async () => {
            try {
                const response = await getVersions();
                dispatch(setVersions(response.data.versions));
            } catch (error) {
                toast("Something went wrong!", { type: "error" })
            }
        })();
    }, []);

    useEffect(() => {
        try {
            if (query === '') {
                setResult([]);
                return;
            }
            const timer = setTimeout(async () => {
                const response = await getMarkdocs(version, query);
                setResult(response.data.markdocs);
            }, 500)

            return () => clearTimeout(timer)
        } catch (error) {
            toast("Something went wrong!", { type: "error" })
        }
    }, [query]);

    return (
        <>
            <div className='hidden lg:flex p-3 items-center border-b border-gray-300 sticky top-0 bg-white z-10'>
                <Link to='/'>
                    <img src={`${process.env.REACT_APP_BASE_URL}/images/logo.png`} className='w-48 h-12' alt="logo" />
                </Link>
                <div className="flex flex-grow justify-between items-center pl-32">
                    <div>
                        <ul className='flex items-center font-bold [&>li]:p-3'>

                            {
                                authenticated &&
                                <>
                                    <li>
                                        <Link to='/v2' className='no-underline hover:border-b-2 border-black'>
                                            Documents
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={managementUrl()} className='no-underline hover:border-b-2 border-black'>
                                            Management Page
                                        </Link>
                                    </li>
                                </>
                            }
                            <li onClick={() => { setOpen(true) }}>
                                <div className="border w-64 p-1.5 rounded-lg flex justify-between items-center font-normal text-sm text-gray-500 bg-gray-100 hover:scale-105 transition ease-in-out cursor-pointer">
                                    <div className="flex items-center">
                                        <MagnifyingGlassIcon className='h-4 w-4 mr-2' />
                                        Search the docs
                                    </div>
                                    <div className=" w-5 h-6 bg-white rounded-md flex justify-center items-center italic border border-gray-300">
                                        /
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {
                        !isEditorPage() &&
                        <div className="flex items-center font-bold">
                            Version:
                            <select className="ml-2 w-12" value={version} onChange={(event) => navigate(event.target.value)}>
                                {versions?.map((item) => (
                                    <option key={item.id} value={item.slug}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    }
                </div>
            </div>
            <div className="block lg:hidden p-2 items-center border-b border-gray-300 sticky top-0 bg-white z-10">
                <ul className='flex items-center justify-between font-bold'>
                    <Link to='/'>
                        <img src={`${process.env.REACT_APP_BASE_URL}/images/logo.png`} className='w-36 h-9' alt="logo" />
                    </Link>
                    <div onClick={() => { setOpen(true) }} >
                        <div className="border w-40 p-1 rounded-lg flex justify-between items-center font-normal text-sm text-gray-500 bg-gray-100 hover:scale-105 transition ease-in-out cursor-pointer">
                            <div className="flex items-center">
                                <MagnifyingGlassIcon className='h-4 w-4 mr-2' />
                                Search
                            </div>
                            <div className=" w-5 h-6 bg-white rounded-md flex justify-center items-center italic border border-gray-300">
                                /
                            </div>
                        </div>
                    </div>
                    {
                        !isEditorPage() &&
                        <li className="flex items-center font-bold">
                            <select className="w-14" value={version} onChange={(event) => navigate(event.target.value)}>
                                {versions?.map((item) => (
                                    <option key={item.id} value={item.slug}>{item.name}</option>
                                ))}
                            </select>
                        </li>
                    }
                </ul>
            </div>
            <SearchModal open={open} setOpen={setOpen} query={query} setQuery={setQuery} result={result} />
        </>
    )
}

export default Header;
