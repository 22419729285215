import React from "react";

function ThankYouSvg({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="357"
            height="324"
            fill="none"
            viewBox="0 0 357 324"
            {...props}
        >
            <path
                fill="#14142B"
                d="M34.214 88.889s-1.462-3.578-10.474-2.516c-9.012 1.06-9.944 12.99-14.446 21.471-4.502 8.482-9.285 12.597-9.285 12.597s-.66 5.169 9.148 8.087 29.029-1.955 29.029-1.955 2.486-22.579-1.205-29.468c-3.691-6.89-2.767-8.216-2.767-8.216z"
            ></path>
            <path
                fill="#14142A"
                d="M58.483 290.698l-12.21.758s-3.099-23.326-8.799-57.584c0-.114 0-.243-.053-.372-2.334-13.983-4.752-25.769-6.935-31.294 0 0-4.881 20.135-5.715 39.606-.834 19.471-4.722 53.539-4.722 53.539l-11.285 1.675s.213-44.093 1.32-56.901c1.106-12.809-3.934-60.255 4.751-72.003h31.416s10.087 42.926 10.967 61.404c.879 18.478 1.265 61.172 1.265 61.172z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M17.942 295.346l-.182 3.638-.425 8.42-6.32.94-.145-9.087-.053-3.494 7.125-.417zM56.317 304.569l-7.071 1.97s-.167-4.251-.334-8.337c-.106-2.63-.212-5.191-.28-6.503 0-.507-.046-.818-.06-.886l7.624-.758v2.395l.053 5.411.068 6.708z"
            ></path>
            <path
                stroke="#FFC700"
                strokeMiterlimit="10"
                strokeWidth="0.135"
                d="M19.882 305.54s1.175-4.707 3.031-4.472c1.857.235-3.031 4.472-3.031 4.472zM20.415 305.54s4.419-3.115 5.237-1.516c.819 1.599-5.237 1.516-5.237 1.516zM59.028 306.017s.986-4.98 2.1-4.98-2.1 4.98-2.1 4.98zM58.796 307.563s2.592-5.101 3.949-4.002c1.356 1.099-3.95 4.002-3.95 4.002z"
            ></path>
            <path
                fill="#14142A"
                d="M25.404 311.648s-13.476.348-14.453.227c-.978-.121-2.062-.174-2.138-1.235 0-.516.463-3.646.539-4.737.075-1.092-.197-1.66 1.174-2.009 1.372-.348 8.034-1.121 8.838-.295.803.826 3.547 3.971 5.578 4.335 2.031.364 3.737.22 3.88 1.372.145 1.152.19 2.258-3.418 2.342zM64.2 311.648s-13.47.348-14.454.227c-.986-.121-1.956-.417-2.115-.909-.16-.493.447-3.972.515-5.063.069-1.092-.197-1.66 1.175-2.009 1.372-.348 8.034-1.121 8.837-.295.804.826 3.548 3.971 5.586 4.335 2.04.364 3.73.22 3.873 1.372.144 1.152.19 2.258-3.418 2.342z"
            ></path>
            <path
                fill="#000"
                d="M37.42 233.492c-2.335-13.983-4.753-25.769-6.936-31.294l1.448-7.943s3.752 28.346 5.487 39.237z"
                opacity="0.3"
            ></path>
            <path
                fill="#F86700"
                d="M17.928 295.346l-.182 3.638c-1.773.068-5.305.197-6.89.273l-.052-3.494 7.124-.417zM56.262 297.854l-7.352.348a727.81 727.81 0 00-.28-6.503l7.58.758.052 5.397z"
                opacity="0.2"
            ></path>
            <path
                fill="#14142B"
                d="M61.113 290.048l-1.13 5.768-13.46 1.046-1.766-6.814h16.356zM22.407 291.927l-1.182 5.699-14.052.667.007-6.366h15.227z"
            ></path>
            <path
                fill="#FFC700"
                d="M65.616 150.516c-3.032 1.448-11.37 3.032-11.37 3.032s-5.456-14.105-6.896-22.124a13.412 13.412 0 00-11.543-11.217c-3.684-.478-19.236-.319-24.693.803-5.457 1.122-6.405 9.133-6.89 13.946-.485 4.813-3.524 22.737-.962 28.043 2.561 5.305 11.543 3.198 11.543 3.198v1.925h31.423l-.758-14.703s2.971 7.806 7.58 8.125c4.608.318 15.067-7.367 15.067-7.367l-2.501-3.661zm-55.328 8.223s.348-9.155.97-11.793l.947 4.889 1.334 6.904h-3.252z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M30.274 156.662l4.941-5.98 6.935-1.061 1.061 6.162-5.305 1.94s-2.554 3.077-7.655 2.547l.023-3.608zM65.611 150.516l2.57-4.714 6.268-2.638 2.372 6.42-5.192 2.023s-1.357 2.987-3.448 2.6l-2.57-3.691zM30.38 114.462a13.149 13.149 0 000 1.637c.075 1.038-.122 2.941 1.917 3.941-1.053 2.274-3.592 7.973-5.131 9.906-2.342-1.917-7.845-9.656-7.845-9.656s3.866-4.191 1.092-12.089c0 0-3.896-2.849-3.115-4.418.894-1.827 3.433-.842 3.971-.069-.568-.833-3.213-9.981 0-13.346 2.001-2.1 4.002-2.123 7.731-2.274h.31c2.388-.084 4.162 0 4.162 0a16.57 16.57 0 012.13 2.857 21.222 21.222 0 013.031 9.095c.705 7.511-.773 13.597-8.254 14.416z"
            ></path>
            <path
                fill="#14142B"
                d="M18.965 99.516s-3.873-5.192-.674-8.557c2-2.1 7.382-3.472 11.103-3.63-.083.878 0 8.579-10.429 12.187z"
            ></path>
            <path
                fill="#14142B"
                d="M35.758 90.997a10.996 10.996 0 01-6.444-2.945l-.3-.315a20.171 20.171 0 014.521.323 16.817 16.817 0 012.161 2.899l.062.038z"
            ></path>
            <path
                fill="#F86700"
                d="M30.403 116.098a8.69 8.69 0 01-4.199-2.812 11.563 11.563 0 004.176 1.175 13.13 13.13 0 00.023 1.637z"
                opacity="0.2"
            ></path>
            <path
                fill="#000"
                d="M12.212 151.835l-.947-4.889v-7.018s1.091 7.405.947 11.907z"
                opacity="0.2"
            ></path>
            <path
                fill="#FFDCC3"
                d="M319.971 122.652l-8.331-3.359s-10.994 20.047-14.143 21.064c-4.284 1.377-13.153 5.207-18.41 5.879-.714.84-1.881 3.452-1.772 4.401 7.063 2.36 22.584-.773 25.91-3.242 7.567-5.602 16.746-24.743 16.746-24.743z"
            ></path>
            <path
                fill="#FFC700"
                d="M322.532 118.092c1.553 5.736-6.862 17.276-6.862 17.276l-11.027-6.181s2.066-4.746 4.585-9.575c4.04-7.802 11.532-8.062 13.304-1.52z"
            ></path>
            <path
                fill="#000"
                d="M309.017 131.656l4.342 2.435.764-13.328c-2.528 1.94-4.309 7.785-5.106 10.893z"
                opacity="0.2"
            ></path>
            <path
                fill="#FFC700"
                d="M315.276 113.776s-8.583 19.098-3.578 54.658h33.822c.537-5.19-1.243-30.521 5.198-54.977a85.992 85.992 0 00-11.237-1.436 123.903 123.903 0 00-14.37 0 66.138 66.138 0 00-9.835 1.755z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M324.033 284.512l-5.999 2.322 5.167 13.348 5.999-2.322-5.167-13.348zM353.53 307.179h-6.324l-2.318-14.639h6.324l2.318 14.639z"
            ></path>
            <path
                fill="#14142A"
                d="M346.77 306.448h7.105a.525.525 0 01.512.437l.916 5.619a.987.987 0 01-.992 1.125c-2.477-.042-3.67-.193-6.794-.193-1.923 0-5.879.201-8.55.201-2.671 0-2.847-2.62-1.747-2.863 4.939-1.067 6.854-2.52 8.5-3.931.29-.255.663-.395 1.05-.395zM323.025 298.989l6.719-1.966a.552.552 0 01.63.269l2.52 5.039a.972.972 0 01-.26 1.144.964.964 0 01-.37.192c-2.444.672-4.082 1.083-7.139 1.973-2.108.613-4.258 1.613-6.341 2.276-1.444.462-4.838.479-6.593.479-2.595 0-3.175-2.738-2.058-2.864 2.814-.311 3.192-.168 4.746-.949a21.576 21.576 0 006.786-4.686c.366-.417.835-.73 1.36-.907z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M280.142 146.202l-6.954-2.914-2.864 7.71c2.851.434 5.75.434 8.6 0l1.218-4.796zM268.016 143.086l-.185 5.123 2.494 2.772 2.864-7.71-5.173-.185z"
            ></path>
            <path
                fill="#FFCCA7"
                d="M324.489 285.646l-6.017 2.323 2.665 6.902 6.017-2.323-2.665-6.902zM351.212 292.54h-6.324l1.201 7.55h6.324l-1.201-7.55z"
            ></path>
            <path
                fill="#000"
                d="M310.62 158.817c.126 1.52.269 3.073.454 4.669l25.145-8.163a18.007 18.007 0 009.675-6.996c.101-2.243.227-4.603.42-7.064.084-1.192.185-2.418.294-3.661.109-1.243.261-2.621.42-3.948l-36.408 25.163z"
                opacity="0.2"
            ></path>
            <path
                fill="#FFDCC3"
                d="M339.012 95.836c-1.184 4.628-2.578 13.102.47 16.184 0 0-2.133 4.695-10.725 4.695-7.852 0-3.645-4.695-3.645-4.695 5.224-1.226 5.317-5.039 4.628-8.65l9.272-7.534z"
            ></path>
            <path
                fill="#FFCCA7"
                d="M335.23 98.91l-5.484 4.459c.17.83.268 1.673.294 2.519 1.604 1.596 4.821-2.444 5.148-4.51.167-.813.181-1.65.042-2.469zM320.316 95.777a5.546 5.546 0 003.569 2.637c2.016.445 3.024-1.411 2.47-3.276-.496-1.68-2.151-3.939-4.2-3.552a2.802 2.802 0 00-2.233 2.925c.028.447.163.881.394 1.266z"
            ></path>
            <path
                fill="#14142B"
                d="M323.649 80.97c-3.141 1.436-4.25 13.917-.303 15.764 3.948 1.848 6.249-18.493.303-15.764z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M339.964 88.874c-.755 6.265-.839 9.952-4.199 13.16a8.083 8.083 0 01-8.181 2.065 8.077 8.077 0 01-5.559-6.348c-1.016-5.627.386-14.924 6.669-17.419a8.085 8.085 0 0110.743 4.323 8.083 8.083 0 01.527 4.219z"
            ></path>
            <path
                fill="#14142B"
                d="M329.644 84.212c4.653-1.184 8.071-1.99 6.845 1.881-1.621 5.174-3.57 10.18-.958 10.406 1.596.143 8.651-2.847 7.752-5.879-.781-2.637 2.52-3.36 3.108-4.695 2.175-4.837-4.048-4.199-2.94-7.138 1.109-2.94-5.039-7.677-8.398-5.317-5.468 3.813-14.849 3.754-9.919-2.52 3.536-4.476-2.772-3.56-2.772-3.56s3.813 2.259-2.603 5.753c-6.786 3.729-.563 13.748 9.885 11.07z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M342.437 95.777a6.505 6.505 0 01-3.872 2.637c-2.074.445-2.872-1.411-2.091-3.276.697-1.68 2.612-3.939 4.594-3.552 1.982.386 2.436 2.544 1.369 4.19z"
            ></path>
            <path
                fill="#14142B"
                d="M311.696 168.434s-5.678 50.811-6.123 70.094c-.453 20.056 12.308 53.357 12.308 53.357l10.078-4.199s-4.824-27.901-3.144-47.595c1.78-21.476 9.137-71.624 9.137-71.624l-22.256-.033z"
            ></path>
            <path
                fill="#000"
                d="M325.808 182.383c-4.527 9.39-1.478 27.959.974 38.961.933-7.768 2.041-16.201 3.091-24.003-.101-11.993-1.033-21.257-4.065-14.958z"
                opacity="0.2"
            ></path>
            <path
                fill="#14142B"
                d="M328.472 286.248c.05 0 1.193 3.208 1.193 3.208l-11.624 4.888-1.831-3.41 12.262-4.686zM323.698 168.434s4.342 48.325 7.215 66.424c3.158 19.829 11.704 61.594 11.704 61.594h11.582s-3.104-40.16-4.415-59.796c-1.427-21.299-4.266-68.222-4.266-68.222h-21.82z"
            ></path>
            <path
                fill="#14142B"
                d="M354.986 295.613c.051 0 .387 3.234.387 3.234H342.64l-.907-3.712 13.253.478z"
            ></path>
            <path
                fill="#000"
                d="M352.099 296.437a.11.11 0 01-.071-.026.106.106 0 01-.038-.067c-.067-.588-4.651-41.346-5.827-58.983-1.276-19.132-3.846-61.571-3.872-61.999a.113.113 0 01.005-.041.105.105 0 01.056-.06.11.11 0 01.115.021c.02.019.032.044.034.072 0 .428 2.596 42.833 3.872 61.998 1.176 17.637 5.76 58.354 5.819 58.984a.106.106 0 01-.02.079.118.118 0 01-.032.027.1.1 0 01-.041.011v-.016z"
                opacity="0.2"
            ></path>
            <path
                fill="#FFDCC3"
                d="M353.969 121.72l-8.735-2.326s-8.566 21.088-11.598 22.457c-4.116 1.882-14.538 8.399-18.973 11.481-.243 1.05-.05 3.83.504 4.594 7.559-1.377 22.03-6.945 25.053-9.776 6.895-6.458 13.749-26.43 13.749-26.43z"
            ></path>
            <path
                fill="#FFC700"
                d="M344.647 307.556a1.84 1.84 0 01-1.201-.327.846.846 0 01-.26-.79.494.494 0 01.285-.42c.706-.361 2.679.89 2.906 1.033a.142.142 0 01.055.066c.01.027.012.057.004.085a.17.17 0 01-.043.079.168.168 0 01-.075.047 7.917 7.917 0 01-1.671.227zm-.84-1.31a.449.449 0 00-.193 0 .187.187 0 00-.101.176.572.572 0 00.168.546c.328.294 1.151.328 2.234.118a5.57 5.57 0 00-2.133-.84h.025z"
            ></path>
            <path
                fill="#FFC700"
                d="M346.276 307.33a.11.11 0 01-.067 0c-.63-.353-1.814-1.738-1.638-2.444a.516.516 0 01.496-.395.796.796 0 01.63.194c.697.596.739 2.427.739 2.519a.123.123 0 01-.015.08.119.119 0 01-.061.054.143.143 0 01-.084-.008zm-1.125-2.562h-.059c-.193 0-.218.11-.227.152-.1.411.647 1.461 1.269 1.957a3.482 3.482 0 00-.63-1.983.515.515 0 00-.353-.126zM319.598 301.214a2.415 2.415 0 01-1.588 0 .796.796 0 01-.411-.638.489.489 0 01.176-.445c.672-.605 3.418 0 3.729.117a.135.135 0 01.101.135.165.165 0 01-.023.089.162.162 0 01-.07.062c-.618.279-1.258.507-1.914.68zm-1.369-.999a.573.573 0 00-.261.126.182.182 0 00-.059.078.172.172 0 00-.008.098.53.53 0 00.269.428c.445.236 1.478.051 2.813-.512a8.238 8.238 0 00-2.754-.218z"
            ></path>
            <path
                fill="#FFC700"
                d="M321.49 300.534h-.067c-.84-.151-2.52-1.117-2.57-1.839 0-.168.075-.404.478-.538a1.078 1.078 0 01.84.05c.907.446 1.386 2.058 1.411 2.134a.155.155 0 01-.002.077.142.142 0 01-.04.065.152.152 0 01-.05.051zm-1.991-2.125h-.084c-.26.092-.26.202-.26.235 0 .437 1.218 1.235 2.066 1.504a3.17 3.17 0 00-1.176-1.68.77.77 0 00-.546-.059z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M315.376 152.837l-8.76 4.031-1.016 2.52 3.233 2.175s6.047-.655 7.769-4.544l-1.226-4.182z"
            ></path>
            <path
                fill="#263238"
                d="M315.744 158.069a11.807 11.807 0 00-1.63-4.753l1.386-1.042a13.537 13.537 0 011.949 5.502l-1.705.293z"
            ></path>
            <path
                fill="#FFC700"
                d="M317.242 154.03a1.676 1.676 0 01-.088 1.927 1.678 1.678 0 01-3.008-.962 1.681 1.681 0 011.046-1.62 1.528 1.528 0 012.05.655z"
            ></path>
            <path
                fill="#FFDCC3"
                d="M300.692 160.127l1.168 1.176 7.861-3.36-3.108-1.075-5.921 3.259z"
            ></path>
            <path
                fill="#FFC700"
                d="M356.244 119.302c2.309 7.449-6.719 17.637-6.719 17.637l-12.472-5.09a129.521 129.521 0 015.106-12.656c3.83-8.256 11.75-7.408 14.085.109z"
            ></path>
            <circle cx="171.561" cy="166.706" r="107.049" fill="#0061FF"></circle>
            <g filter="url(#filter0_d_1053_23358)">
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="10.56"
                    d="M131.028 172.496l23.16 23.159 57.897-57.898"
                ></path>
            </g>
            <rect
                width="21.271"
                height="21.271"
                x="222.213"
                y="273.755"
                fill="#FFC700"
                rx="5"
            ></rect>
            <rect
                width="37.727"
                height="37.727"
                x="56.85"
                y="48.449"
                fill="#B3D0FF"
                opacity="0.3"
                rx="8.384"
            ></rect>
            <path
                fill="#065AE3"
                d="M225.38 35.94c-3.87-4.21-1.73-11.03 3.851-12.277l29.028-6.48c5.581-1.245 10.418 4.018 8.706 9.474l-8.902 28.379c-1.712 5.456-8.688 7.014-12.558 2.803L225.38 35.94z"
            ></path>
            <circle cx="116.291" cy="308.253" r="15.252" fill="#00B2FF"></circle>
            <defs>
                <filter
                    id="filter0_d_1053_23358"
                    width="215.781"
                    height="192.622"
                    x="63.666"
                    y="78.672"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="8.278"></feOffset>
                    <feGaussianBlur stdDeviation="31.041"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1053_23358"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1053_23358"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default ThankYouSvg;