export const route = {
  render: 'Route',
  children: ['paragraph', 'tag', 'list', 'fence'],
  attributes: {
    method: {
      type: String,
    },
    url: {
      type: String,
    },
  }
};
