import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: [],
    tree: [],
    versions: [],
}

export const markdocs = createSlice({
    name: 'markdocs',
    initialState,
    reducers: {
        setMarkdocs: (state, markdocs) => {
            state.value = markdocs.payload
        },
        setMarkdocTrees: (state, markdocs) => {
            state.tree = markdocs.payload
        },
        setVersions: (state, versions) => {
            state.versions = versions.payload
        },

    },
})

export const { setMarkdocs, setMarkdocTrees, setVersions } = markdocs.actions
export default markdocs.reducer
