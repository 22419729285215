import Header from './HeaderPage';
import Footer from './Footer';
import SideBar from './SideBar';
import { ToastContainer } from 'react-toastify';
import { setNarrowingSidebar } from '../stores/mode';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux';
import { isEditorPage } from '../helpers/urlHelper';

const Layout = ({ children }) => {
    const narrowing = useSelector(state => state.mode.narrowingSidebar);
    const dispatch = useDispatch();
    const arrowAnimation = narrowing
        ? (isEditorPage() ? 'animate-[MoveRightToLeftLg_0.2s_ease-in-out_forwards]' : 'animate-[MoveRightToLeft_0.2s_ease-in-out_forwards]')
        : (isEditorPage() ? 'animate-[MoveLeftToRightLg_0.2s_ease-in-out_forwards]' : 'animate-[MoveLeftToRight_0.2s_ease-in-out_forwards]')

    const wideAnimation = narrowing
        ? (isEditorPage() ? 'animate-[ContentRightToLeftLg_0.2s_ease-in-out_forwards]' : 'animate-[ContentRightToLeft_0.2s_ease-in-out_forwards]')
        : (isEditorPage() ? 'animate-[ContentLeftToRightLg_0.2s_ease-in-out_forwards]' : 'animate-[ContentLeftToRight_0.2s_ease-in-out_forwards]')

    return (
        <>
            <Header />
            <div className='flex h-full'>
                <SideBar />
                <div className={`fixed top-96 z-10 ${arrowAnimation} ${narrowing ? '-left-6' : '-left-4'}`}
                >
                    <div className={`rounded-full border bg-white hover:scale-110 cursor-pointer`} onClick={() => dispatch(setNarrowingSidebar(!narrowing))}>
                        {narrowing ? <ChevronRightIcon className='w-7 h-7' /> : <ChevronLeftIcon className='w-7 h-7' />}
                    </div>
                </div>
                <main className={`w-full grow ${wideAnimation} ${narrowing ? 'pl-12' : ''}`}>
                    {children}
                </main>
                <ToastContainer />
            </div>
            <Footer />
        </>
    )
}

export default Layout;
