import { useEffect, useState } from 'react';
import { Document } from '../components/index.js';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { updateMarkdoc, createMarkdocs, getMarkdocDetail, getMarkdocTrees, getMarkdocs } from '../services/markdocs.js';
import { getMarkdocTemplateDetail } from '../services/markdoc-templates.js';
import { markdocTransform, markdocRender, markdocFormat } from '../helpers/markdocHelper.js';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { managementUrl } from '../helpers/urlHelper.js';
import { toast } from 'react-toastify';
import { setEditing } from '../stores/mode';
import { useDispatch } from 'react-redux';
import { setMarkdocTrees, setMarkdocs } from '../stores/markdocs.js';

require('../codemirror/markdoc.js');

function MarkdocEditor() {
    const [key, setKey] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [content, setContent] = useState(null);
    const [mardock, setMarkdoc] = useState();
    const [originText, setOriginText] = useState('');
    const [initText, setInitText] = useState('');
    const [currentText, setCurrentText] = useState('');
    const template = searchParams.get('template');
    const parent = searchParams.get('parent_id');

    const updateContent = (rawText) => {
        setCurrentText(rawText);
        setInitText(rawText);
        setContent(markdocTransform(rawText));
    }

    useEffect(() => {
        (async () => {
            let rawText = '';
            try {
                if (template) {
                    const isTemplate = ["template_1", "template_2"].includes(template);
                    const response = isTemplate ? await getMarkdocTemplateDetail(template.split('template_')[1]) : await getMarkdocDetail(template)
                    rawText = isTemplate ? response.data.template.content : response.data.markdoc.content
                    setMarkdoc(null)
                }
                if (id) {
                    const response = await getMarkdocDetail(id);
                    const markdoc = response.data.markdoc;
                    setMarkdoc(markdoc)
                    rawText = markdoc.content ?? '';
                }
            } catch (error) {
                toast("Something went wrong!", { type: "error" })
            }
            updateContent(rawText);
            setOriginText(rawText)
        })();
        document.title = 'Management Page';
        dispatch(setEditing(false))
    }, [location]);

    const save = async () => {
        try {
            if (id) {
                await updateMarkdoc(id, currentText);
                setInitText(currentText);
                setOriginText(currentText)
                toast("Updated document successfully!", { type: "success" })
            } else if (parent) {
                const result = await createMarkdocs(parent, currentText, searchParams.get('name') ?? mardock.name);
                const markdocResponse = await getMarkdocs();
                dispatch(setMarkdocs(markdocResponse.data.markdocs));
                const response = await getMarkdocTrees();
                dispatch(setMarkdocTrees(response.data.markdocs));
                navigate(managementUrl() + result.data.markdoc.id);
                toast("Created document successfully!", { type: "success" })
            }
        } catch (error) {
            toast("Something went wrong!", { type: "error" })
        }
    }

    const reset = () => {
        setKey(key + 1);
        updateContent(originText);
    }
    const editorConfig = {
        autofocus: false,
        styleSelectedText: true,
        lineNumbers: true,
        theme: 'material',
        mode: 'markdoc',
        lineWrapping: true,
    }

    const format = () => {
        const rawText = markdocFormat(currentText);
        setKey(key + 1);
        updateContent(rawText);
    }

    return (
        <div className='documentation'>
            {
                initText ?
                    <>
                        <div className='bg-[#263238] border-b border-slate-200'>
                            <div className='p-3 [&>button]:px-3 [&>button]:border [&>button]:border-slate-200 text-white [&>button]:no-underline'>
                                <button
                                    onClick={() => { save() }}
                                    className='border-r-0 hover:bg-gray-200 hover:text-black'
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => { format() }}
                                    className='border-r-0 hover:bg-gray-200 hover:text-black'
                                >
                                    Format
                                </button>
                                <button
                                    onClick={() => { reset() }}
                                    className='border-r-0 hover:bg-gray-200 hover:text-black'
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='w-1/2' onClick={() => { dispatch(setEditing(true)) }}>
                                <CodeMirror
                                    key={key}
                                    value={initText}
                                    className='markdoc-editor'
                                    options={editorConfig}
                                    onChange={(editor, data, value) => {
                                        setCurrentText(value)
                                        setContent(markdocTransform(value));
                                    }}
                                />
                            </div>
                            <div className='w-1/2 h-[85vh] overflow-auto p-8'>
                                <Document name={searchParams.get('name') ?? mardock?.name} ancestors={mardock?.ancestors} slug={mardock?.slug}>
                                    {
                                        markdocRender(content)
                                    }
                                </Document>
                            </div>
                        </div></>
                    :
                    <>
                        <h1 className='text-center text-[4rem] mt-24'>Management Page</h1>
                        <p className='text-center mt-8 text-2xl'>Please select a document to start editing</p>
                    </>
            }
        </div>

    );
}


export default MarkdocEditor;
