import { configureStore } from '@reduxjs/toolkit'
import { mode, markdocs, auth, scrollHandler } from './stores'

export const store = configureStore({
    reducer: {
        mode,
        markdocs,
        auth,
        scrollHandler
    },
})
