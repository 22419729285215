import SortableTree, { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import { rebuildTree } from '../services/markdocs';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export function SortScreen({ renderActions, treeClass = null, ...props }) {

    const markdocTrees = useSelector((state) => state.markdocs.tree);
    const [trees, setTrees] = useState();

    useEffect(() => {
        setTrees(toggleExpandedForAll({
            treeData: markdocTrees,
            expanded: true,
        }));
    }, [markdocTrees]);

    const endSortHandler = async (newTrees) => {
        setTrees(newTrees)
        await rebuildTree(newTrees)
    }


    return (
        <div {...props} className='h-[90vh]'>
            {
                <SortableTree
                    treeData={trees}
                    onChange={trees => endSortHandler(trees)}
                    generateNodeProps={({ node, path }) => ({
                        title: (
                            renderActions ? renderActions(node) : node.name
                        ),
                    })}
                    className={treeClass}
                />
            }
        </div >
    );
}
