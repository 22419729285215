import { Children, useState } from "react";
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'

export function Param({ name, type, required, more, children, ...props }) {

    const [show, setShow] = useState(false);
    let childs = [];
    let others = [];

    Children.forEach(children, (child) => {
        child.type?.name == 'Param' ? childs.push(child) : others.push(child)
    });

    return (
        <div {...props} >
            <div className='border-gray-300 text-sm py-3 param'>
                <span className='font-semibold mr-2'>{name}</span>
                <span className={`text-xs mr-1 rq-${required?.toLowerCase()}`}>{required}</span>
                <span className={`text-xs mr-1 type-${type?.toLowerCase()}`}>{type}</span>
                <span className='text-xs mr-1'>{more}</span>
                <span className='mt-2'>{others}</span>
                {
                    childs.length > 0 &&
                    <div className={`border border-gray-300 rounded-2xl transition my-2 ${show ? 'w-full' : 'w-1/2 min-w-[200px]'}`}>
                        <div className="flex items-center p-1 cursor-pointer font-medium px-3" onClick={() => { setShow(!show) }}>
                            {show ? <XMarkIcon className="w-5 h-5 mr-1" /> : <PlusIcon className="w-5 h-5 mr-1" />}
                            {show ? 'Hide' : 'Show'} child attributes
                        </div>
                        {
                            show &&
                            childs.map((child, index) => (
                                <div key={index} className='py-1 px-3 border-gray-300 border-t'>{child}</div>
                            ))
                        }
                    </div>
                }
            </div>
        </div >
    );
}
