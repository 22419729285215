import axios from 'axios';

const url = `${process.env.REACT_APP_TAPI_API}/markdoc-templates`;

const getMarkdocTemplates = () => {
    return axios.get(url)
}

const getMarkdocTemplateDetail = (id) => {
    return axios.get(`${url}/${id}`)
}

export {
    getMarkdocTemplates,
    getMarkdocTemplateDetail
}
