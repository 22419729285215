import axios from 'axios';

const url = `${process.env.REACT_APP_TAPI_API}/markdocs`;

const getMarkdocs = (version = null, query) => {
    return axios.get(url, { params: { version, query } })
}
const getVersions = () => {
    return axios.get(`${url}/versions`)
}

const getMarkdocTrees = (version = null) => {
    return axios.get(`${url}/trees`, { params: { version } })
}

const createMarkdocs = (parent, content, name) => {
    return axios.post(url, { parent_id: parent, content, name })
}

const updateMarkdoc = (id, content, name) => {
    return axios.put(`${url}/${id}`, { content, name })
}

const deleteMarkdoc = (id) => {
    return axios.delete(`${url}/${id}`)
}

const getMarkdocDetail = (id) => {
    return axios.get(`${url}/${id}`)
}

const rebuildTree = (trees) => {
    return axios.put(`${url}/rebuild-tree`, { trees })
}

export {
    getMarkdocs,
    getMarkdocTrees,
    createMarkdocs,
    updateMarkdoc,
    getMarkdocDetail,
    deleteMarkdoc,
    getVersions,
    rebuildTree
}
