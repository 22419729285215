export const activeSideBar = (slug) => {
    const tab = document.getElementById(`tab-${slug}`);
    if (!tab) return;
    tab.classList.add("active");
}

export const deactiveSideBar = (slug) => {
    const elements = document.getElementsByClassName('docs');
    Array.from(elements).forEach(function (element) {
        const id = element?.getAttribute('id');
        if (id && id !== slug && document.getElementById(`tab-${id}`)) {
            document.getElementById(`tab-${id}`).classList.remove("active")
        }
    });
}

export const getCurrentSideBar = () => {
    let currentHeight = 0;
    let current = null;
    const elements = document.getElementsByClassName('docs');
    Array.from(elements).forEach(function (element, index) {
        if (window.scrollY >= (currentHeight - 180) && window.scrollY <= (currentHeight += element.offsetHeight)) {
            current = elements[index]
        }
    });

    return current;
}
