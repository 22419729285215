import { matchPath } from 'react-router-dom';

export function isEditorPage() {
    const path = window.location.pathname
    return matchPath(process.env.REACT_APP_BASE_URL + managementUrl() + ':id', path)
        || matchPath(process.env.REACT_APP_BASE_URL + managementUrl(), path)
}

export function managementUrl() {
    return '/articles/'
}
