import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isScrolling: false,
}

export const scrollHandler = createSlice({
    name: 'scrollHandler',
    initialState,
    reducers: {
        setIsScrolling: (state, value) => {
            state.isScrolling = value.payload
        },
    },
})

export const { setIsScrolling } = scrollHandler.actions

export default scrollHandler.reducer
