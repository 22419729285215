import React from 'react';
import Markdoc from '@markdoc/markdoc';
import { document, route, param, fence, left, right, box, column, child } from '../tags';
import { Document, Route, Param, Fence, Left, Right, Box, Column, Child } from '../components';

export const components = {
    Document,
    Route,
    Param,
    Fence,
    Left,
    Right,
    Box,
    Column,
    Child
};

export let markdocConfig = {
    tags: {
        document,
        route,
        param,
        left,
        right,
        box,
        column,
        child
    },
    nodes: {
        fence
    },
    variables: {
    }
};


export function markdocRenderByRawText(rawText) {
    return Markdoc.renderers.react(
        Markdoc.transform(Markdoc.parse(rawText), markdocConfig), React, {
        components
    })
}

export function markdocRender(content) {
    return Markdoc.renderers.react(content, React, {
        components
    })
}

export function markdocTransform(rawText) {
    return Markdoc.transform(Markdoc.parse(rawText), markdocConfig)
}

export function markdocFormat(rawText) {
    return Markdoc.format(Markdoc.parse(rawText))
}
