import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authenticated: undefined,
}

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, auth) => {
            state.authenticated = auth.payload
        },
    },
})

export const { setAuth } = auth.actions

export default auth.reducer
