import { useSelector } from "react-redux";
import Layout from './Layout';

const Authenticate = ({ auth, children }) => {
    const authenticated = useSelector(state => state.auth.authenticated)
    /*
        authenticated have an initial value of undefined,
        after calling the authentication api, the value will be true or false
    */
    if (auth.required) {
        // don't render ui until response result from api
        if (authenticated == false) {
            window.location.href = '/'
        }
        if (authenticated) {
            return (
                <Layout>
                    {children}
                </Layout>
            );
        }
        return;
    }

    return (
        <Layout>
            {children}
        </Layout>
    );
}

export default Authenticate;
