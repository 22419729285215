import { useEffect, useState } from 'react';
import { TrashIcon, PencilSquareIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateDocumentModal, DeleteDocumentModal } from '../components/modals';
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { isEditorPage, managementUrl } from '../helpers/urlHelper';
import { setEditing, setNarrowingSidebar } from '../stores/mode';
import { setIsScrolling } from '../stores/scrollHandler';
import { activeSideBar, deactiveSideBar } from '../helpers/scrollHelper';
import { SortScreen } from '../components';

export default function SideBar() {
    const location = useLocation();
    let hash = location.hash.slice(1);
    const [url, setUrl] = useState('');
    const [currentDoc, setCurrentDoc] = useState(null);
    const { id, version } = useParams();
    const narrowing = useSelector(state => state.mode.narrowingSidebar);
    const mode = useSelector((state) => state.mode.value);
    const markdocs = useSelector((state) => state.markdocs.value);
    const markdocTrees = useSelector((state) => state.markdocs.tree);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const isManagementMode = () => {
        return mode == 'management';
    }
    const dispatch = useDispatch()

    const traverse = (markdocTrees) => {
        return markdocTrees?.map((markdoc) => (
            <div key={markdoc.id}>
                <Disclosure as="div"
                    defaultOpen={false}
                    className={`${narrowing ? 'hidden' : ''}`}
                >
                    {({ open }) => (
                        <>
                            {
                                <Disclosure.Button
                                    id={`tab-${markdoc.slug}`}
                                    className={
                                        `group text-gray-700 flex items-center w-full hover:bg-gray-200
                                    ${id == markdoc.id ? 'bg-gray-200 font-semibold ' : ''}
                                    ${markdoc.children.length > 0 ? '' : 'pl-3'}`
                                    }
                                >
                                    {
                                        markdoc.children.length > 0 &&
                                        <ChevronRightIcon
                                            className={`h-5 w-5 shrink-0 ${open ? 'rotate-90 text-gray-700' : 'text-gray-500'}`}
                                            aria-hidden="true"
                                        />
                                    }
                                    {renderActions(markdoc)}
                                </Disclosure.Button>
                            }
                            <Disclosure.Panel as="ul" className={`${narrowing ? '' : 'pl-3'}`}>
                                {traverse(markdoc.children)}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        ))
    }

    useEffect(() => {
        setUrl(isManagementMode() ? managementUrl() : (version ? `/${version}/#` : '/#'));
    }, [version, mode]);

    useEffect(() => {
        dispatch(setEditing(openUpdateModal))
    }, [openUpdateModal]);

    const editName = (id) => {
        setCurrentDoc(markdocs.filter((markdoc) => markdoc.id == id)[0]);
        setOpenUpdateModal(true);
    }

    const newDocument = (parent) => {
        setCurrentDoc({ parent_id: parent });
        setOpenUpdateModal(true);
    }

    const deletePage = (id) => {
        setDeleteId(id);
        setOpenDeleteModal(true);
    }

    let scrollTimeout;

    const scrollByLink = (slug) => {
        const element = document.getElementById(slug)
        if (!element) return;
        dispatch(setIsScrolling(true))
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        deactiveSideBar(slug);
        activeSideBar(slug);
    }

    const clickSideBar = (slug) => {
        scrollByLink(slug)
        if (window.screen.width < 1200) dispatch(setNarrowingSidebar(true))
    }

    useEffect(() => {
        const scrollHandler = () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                dispatch(setIsScrolling(false))
            }, 100);
        }
        document.addEventListener("scroll", scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    const renderActions = (markdoc) => {

        return (
            <Link
                to={`${url + (isManagementMode() ? markdoc.id : markdoc.slug)}`}
                className={`group hover:bg-gray-200 flex no-underline py-1.5 w-full justify-between pl-2 ${id == markdoc.id ? 'bg-gray-200 font-semibold' : 'font-medium'}`}
                onClick={() => { clickSideBar(markdoc.slug) }}
            >
                <div className={`${hash != markdoc.slug ? 'truncate' : ''} text-left`}>{markdoc.name}</div>
                {
                    isManagementMode() &&
                    <div className={`group-hover:flex ${id == markdoc.id ? 'flex' : 'hidden'}`}>
                        <div onClick={(event) => { event.preventDefault(); newDocument(markdoc.id) }}>
                            <DocumentPlusIcon className='w-5 h-5 ml-1 text-green-500 hover:scale-125' />
                        </div>
                        {
                            markdoc.parent_id &&
                            <>
                                <div onClick={(event) => { event.preventDefault(); editName(markdoc.id) }}>
                                    <PencilSquareIcon className='w-5 h-5 ml-1 text-blue-500 hover:scale-125' />
                                </div>
                                <div onClick={(event) => { event.preventDefault(); deletePage(markdoc.id) }}>
                                    <TrashIcon className='w-5 h-5 ml-1 text-red-500 hover:scale-125' />
                                </div>
                            </>
                        }

                    </div>
                }

            </Link>
        )
    }

    const animation = narrowing
        ? (isEditorPage() ? 'animate-[NarrowLg_0.2s_ease-in-out_forwards]' : 'animate-[Narrow_0.2s_ease-in-out_forwards]')
        : (isEditorPage() ? 'animate-[WidenLg_0.2s_ease-in-out_forwards]' : 'animate-[Widen_0.2s_ease-in-out_forwards]')

    return (
        <nav className={`sidebar gap-y-5 border-r border-gray-200 fixed z-10 bg-white 
            ${animation}
            ${isEditorPage() ? '' : 'h-[100vh] overflow-y-auto'}`}
        >
            <ul className={`${narrowing ? '' : 'space-y-1 pb-32'}`}>
                {isEditorPage() ?
                    <SortScreen renderActions={renderActions} treeClass={narrowing ? 'hidden' : ''} /> : traverse(markdocTrees)
                }
            </ul>
            <UpdateDocumentModal open={openUpdateModal} setOpen={() => setOpenUpdateModal()} markdoc={currentDoc} />
            <DeleteDocumentModal open={openDeleteModal} setOpen={() => setOpenDeleteModal()} markdocId={deleteId} />
        </nav>
    )
}
