import { useEffect } from "react";
import Routers from './routers';
import { checkAuth } from "./services/authenticate";
import { useDispatch } from "react-redux";
import { setAuth } from "./stores/auth";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            try {
                const response = await checkAuth();
                dispatch(setAuth(response.data.status == 'Authenticated' ? true : false));
            } catch (error) {
                dispatch(setAuth(false));
            }
        })();
    }, []);

    return (
        <>
            <Routers />
        </>
    );
}

export default App;
